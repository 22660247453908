// .c-form .hs-submit .hs-button,
.button {
  @include button-reset;
  @include sf-typography('button');

  .sprite {
    margin-left: sp(2);

    &,
    svg {
      width: sp(5);
      height: sp(5);
    }
  }

  &:active,
  &.is-active {
    transform: scale3d(0.95, 0.95, 1);
  }

  &:disabled,
  &.is-disabled {
    transform: scale3d(1, 1, 1);
  }
}
