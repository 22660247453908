.c-hero {
  $this: &;
  position: relative;
  color: $color-white;

  &__background {
    @include position-fill;
    z-index: -1;
  }

  &__wrapper {
    min-height: 480px;
    padding-top: sp(8);
    padding-bottom: sp(8);
    // flex used to center content vertically to hero
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;

    @include mq($from: desktop) {
      min-height: 770px;
      padding-bottom: sp(16);
      padding-top: sp(16);
    }
  }

  // ==========================================================================||
  //  Flag Variation
  // ==========================================================================||
  &.is-small {
    #{$this}__wrapper {
      min-height: 260px;

      @include mq($from: desktop) {
        min-height: 500px;
      }
    }
  }
}
