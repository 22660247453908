// a,
.link {
  color: inherit;
  text-decoration: none;
  transition: all $transition-default;

  // to let iOS apply :hover on click
  &[href] {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}

// Default anchor styling
// Used on its own without any classes, richtext etc

// a:not([class]) {
//   color: inherit;
//   text-decoration: underline;

//   &:hover,
//   &:focus {
//     text-decoration: none;
//   }
// }

// Anchor style class
// Used to overwrite default anchor styling
// Inherits font styling but adds hover state

.link--default {
  &:hover,
  &.is-hovered,
  &:focus,
  &.is-focused,
  &.is-active {
    text-decoration: underline;
  }
}

// Underline anchor
// On hover, line removed
// update components.header-main if this changes

.link--underline {
  text-decoration: underline;

  &:hover,
  &.is-hovered,
  &:focus,
  &.is-focused,
  &.is-active {
    text-decoration: none;
  }
}
