// ==========================================================================||
//	Add color utility
// ==========================================================================||

$text-colors: (
  'body': $color-text-tlight
) !default;

@each $color-namespace, $color-hex in $text-colors {
  .u-text-color-#{$color-namespace} {
    color: $color-hex;
  }
}

// ==========================================================================||
//	Add font-weight utilitiy
// ==========================================================================||

$text-weights: (
  'bold': 700,
  'regular': 400
) !default;

// set true to include breakpoint separator
@each $weight-namespace, $weight-value in $text-weights {
  .u-text-weight-#{$weight-namespace} {
    font-weight: $weight-value;
  }
}

// ==========================================================================||
//  Add text-align utility
// ==========================================================================||

$text-alignments: (
  'center': center,
  'left': left,
  'right': right
) !default;

// set true to include breakpoint separator
$text-alignments-bp: false !default;

@mixin hf-text-align($breakpoint: null) {
  @each $alignment-namespace, $alignment-value in $text-alignments {
    .u-text-align-#{$alignment-namespace}#{$breakpoint} {
      text-align: $alignment-value;
    }
  }
}

@include hf-text-align;

@if $text-alignments-bp == true {
  @each $inuit-bp-name, $inuit-bp-value in $mq-breakpoints {
    @include mq($from: $inuit-bp-name) {
      @include hf-text-align(#{$global-separator}#{$inuit-bp-name});
    }
  }
}

// ==========================================================================||
//  Add text-transform utility
// ==========================================================================||

.u-text-uppercase {
  text-transform: uppercase;
}

.u-text-lowercase {
  text-transform: lowercase;
}

// ==========================================================================||
//  Single line
//  Utilize @include single-line to force one liner elements
// ==========================================================================||

.u-single-line {
  @include single-line;
}
