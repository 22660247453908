// prettier-ignore
$ratio-modifiers: (
  "2\\:1"   : (2:1),
  "4\\:3"   : (4:3),
  "16\\:9"  : (16:9)
) !default;

// ==========================================================================||
//	Usage:
//  <div class="o-ratio  o-ratio--16:9">
//    <div class="o-ratio__content"></div>
//  </div>
// ==========================================================================||

// validate settings

@if not variable-exists($name: ratio-modifiers) {
  @error "$ratio-modifiers was not set";
}

@if type-of($value: $ratio-modifiers) != 'map' {
  @error "$ratio-modifiers is not a map";
}

@if not map-has-values($ratio-modifiers) {
  @error "$ratio-modifiers is invalid";
}

.o-ratio {
  $this: &;
  position: relative;
  display: block;

  &:before {
    content: '';
    display: block;
    width: 100%;

    padding-bottom: 100%; // Defaults to a perfect square
  }

  &__content,
  > iframe,
  > embed,
  > object {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  // ==========================================================================||
  //	Ratio Variants
  //  - Generate a series of ratio modifier classes based on $ratio-modifiers
  //
  //  Usage:
  //  <div class="o-ratio  o-ratio--custom"></div>
  // ==========================================================================||

  @each $ratio-name, $ratio-value in $ratio-modifiers {
    @each $antecedent, $consequent in $ratio-value {
      @if (type-of($antecedent) != number) {
        @error "`#{$antecedent}` needs to be a number.";
      }

      @if (type-of($consequent) != number) {
        @error "`#{$consequent}` needs to be a number.";
      }

      &--#{$ratio-name}:before {
        padding-bottom: math.div($consequent, $antecedent) * 100%;
      }
    }
  }
}
