// ==========================================================================||
//	Background Colors
//  utilities.bg-colors
// ==========================================================================||

// do not remove - white | grey
$background-colors: (
  'black': $color-black,
  'white': $color-white,
  'primary': $color-primary,
  'grey': $color-grey,
);

// ==========================================================================||
//	Text Colors
//  utilities.text
// ==========================================================================||

$text-colors: (
  'black': $color-text-tlight,
  'white': $color-text-tdark,
  'primary': $color-primary,
  'disabled': $color-disabled,
);

// ==========================================================================||
//	Text Weights
//  utilities.text
// ==========================================================================||

$text-weights: (
  'regular': $font-weight-regular,
  'medium': $font-weight-medium,
  'semibold': $font-weight-semibold,
  'bold': $font-weight-bold,
);

// ==========================================================================||
//  Text Alignments
//  utilities.text
// ==========================================================================||

$text-alignments: (
  'center': center,
  'left': left,
  'right': right,
) !default;

$text-alignments-bp: false;
