// ==========================================================================||
//  Tertiary buttons
//  Simple underline below text
//  Hover removes underline and change text color
//  Supports optional icon on right hand side only, flips when active
// ==========================================================================||

@mixin c-button-tertiary() {
  position: relative;
  color: $color-primary;

  // underline
  &:before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $color-primary;
    transition: width $transition-default;
  }

  .sprite {
    margin-left: sp(2);

    &,
    svg {
      width: sp(3);
      height: sp(3);
    }

    svg {
      fill: $color-primary;
    }
  }

  &:hover,
  &.is-hovered {
    &,
    &:focus,
    &.is-focused {
      color: $color-black;

      &:before {
        width: 0;
      }

      .sprite svg {
        fill: $color-black;
      }
    }
  }

  &:focus,
  &.is-focused {
    color: $color-bgblue;

    &:before {
      background-color: $color-bgblue;
    }

    .sprite svg {
      fill: $color-bgblue;
    }
  }

  &:active,
  &.is-active {
    color: $color-purple !important;

    &:before {
      background-color: $color-purple !important;
    }

    .sprite svg {
      fill: $color-purple !important;
    }
  }

  &:disabled,
  &.is-disabled {
    color: $color-disabled;

    &:before {
      width: 0;
    }

    .sprite svg {
      fill: $color-disabled;
    }
  }
}

.c-button-tertiary {
  @include c-button-tertiary;
}
