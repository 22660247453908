// $spacings-dir: (
//   t: top,
//   b: bottom,
//   l: left,
//   r: right,
// ) !default;

$spacings-separator: $global-separator !default;

@function escape-number($value) {
  @if type-of($value) != 'number' {
    @return $value;
  }
  $int: math.floor($value);
  $fract: $value - $int;
  @if ($fract == 0) {
    @return $int;
  }
  @while ($fract != math.floor($fract)) {
    $fract: $fract * 10;
  }
  @return $int + '\\.'+ $fract;
}

// ==========================================================================||
//  Toggle comments on/off for what you need
//  Don't bloat the css output!
// ==========================================================================||

@mixin make-spacing($spacings, $breakpoint: null) {
  @each $sp, $sp-value in $spacings {
    @each $sp-dir, $sp-property in (t: top, b: bottom, l: left, r: right) {
      // .u-m-#{escape-number($sp)} {
      //   margin: sp($sp);
      // }

      .u-m#{$sp-dir}-#{escape-number($sp)}#{$breakpoint} {
        // margin-#{$sp-property}: sp($sp);
        margin-#{$sp-property}: $sp-value;
      }

      // .u-p-#{escape-number($sp)} {
      //   padding: sp($sp);
      // }

      .u-p#{$sp-dir}-#{escape-number($sp)}#{$breakpoint} {
        // padding-#{$sp-property}: sp($sp);
        padding-#{$sp-property}: $sp-value;
      }
    }
  }
}

@include make-spacing($spacings);

// Uncomment to enable this for all breakpoints
@if (variable-exists(mq-breakpoints)) {
  @each $bp-name, $bp-value in $mq-breakpoints {
    @include mq($from: $bp-name) {
      @include make-spacing($spacings, #{$spacings-separator}#{$bp-name});
    }
  }
}
