.c-pagination {
  $this: &;

  @include reset-list;
  align-items: center;
  display: flex;
  justify-content: center;

  &__item {
    margin-right: sp(4);

    &:last-child {
      margin-right: 0;
    }
  }

  // &__page {
  //   @include sf-typography('button');
  //   position: relative;
  //   align-items: center;
  //   background-color: transparent;
  //   display: inline-flex;
  //   height: sp(6);
  //   justify-content: center;
  //   text-align: center;
  //   width: sp(6);

  //   &:before {
  //     content: '';

  //     position: absolute;
  //     bottom: 0;
  //     left: 4px;
  //     background-color: $color-yellow; // customize if styling change
  //     height: 1px;
  //     opacity: 0;
  //     width: 16px;
  //   }

  //   &.is-active {
  //     color: $color-yellow; // customize if styling change

  //     &:before {
  //       opacity: 1;
  //     }
  //   }
  // }

  &__page {
    @include button-reset;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background-color: transparent;
    border-radius: 4px;
    will-change: background-color, color;
    color: $color-white;
    text-align: center;
    margin: 0 1px;

    .sprite {
      height: 20px;
      width: 20px;

      svg {
        height: 12px;
        width: 12px;
        fill: $color-white;
      }
    }

    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused,
    &.is-active {
      background-color: $color-lightgrey;
      color: $color-black;

      .sprite svg {
        fill: $color-black;
      }
    }

    &:disabled,
    &.is-disabled {
      background-color: transparent;
      color: $color-disabled;

      .sprite svg {
        fill: $color-disabled;
      }
    }

    &.go-to-start,
    &.go-to-end {
      .sprite {
        + .sprite {
          margin-left: -8px;
        }
      }
    }
  }
}
