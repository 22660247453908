// ==========================================================================||
//	transition styles
// ==========================================================================||

$transition-easing: cubic-bezier(0.25, 0.01, 0.25, 1);

$transition-duration: 200ms;
$transition-duration-slow: 350ms;
$transition-duration-slower: 500ms;

$transition-default: $transition-duration $transition-easing;
$transition-slow: $transition-duration-slow $transition-easing;
$transition-slower: $transition-duration-slower $transition-easing;

// ==========================================================================||
//	box-shadow styles
// ==========================================================================||

$box-shadow-default: 0 0 12px rgba($color-black, 0.12);
$box-shadow-default-hover: 0 0 15px rgba(0, 0, 0, 0.2);

// ==========================================================================||
//  border-radius styles
// ==========================================================================||

$border-radius-default: 8px;

// ==========================================================================||
//  on focus outline style
// ==========================================================================||

$outline-focus-default: 2px auto $color-focus;
