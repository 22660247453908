/// Grid system
// Generate semantic grid columns with these mixins.

// ==========================================================================||
//	1. overwrite float
//  2. center element horizontally
// ==========================================================================||

@mixin make-wrapper() {
  float: none !important; /* [1] */
  margin-left: auto !important; /* [2] */
  margin-right: auto !important; /* [2] */
  width: 100%;
}

// ==========================================================================||
//	1. Set wrapper max-width property
// ==========================================================================||

@mixin set-wrapper-max-width($max-width) {
  max-width: $max-width; /* [1] */
}

// ==========================================================================||
//  Calls @include set-wrapper-max-width across defined breakpoints
// ==========================================================================||

@mixin set-wrapper-bp-max-width($wrapper-max-width-map) {
  @each $bp-name, $bp-max-width in $wrapper-max-width-map {
    @if $bp-name == 'mobile' {
      @include set-wrapper-max-width($bp-max-width);
    } @else {
      @include mq($from: $bp-name) {
        @include set-wrapper-max-width($bp-max-width);
      }
    }
  }
}

// ==========================================================================||
//	1. Set wrapper padding
// ==========================================================================||

@mixin set-wrapper-padding($padding) {
  padding-left: $padding; /* [1] */
  padding-right: $padding; /* [1] */
}

// ==========================================================================||
//	Calls @include set-wrapper-padding across defined breakpoints
// ==========================================================================||

@mixin set-wrapper-bp-padding($wrapper-padding-map) {
  @each $bp-name, $bp-padding in $wrapper-padding-map {
    @if $bp-name == 'mobile' {
      @include set-wrapper-padding($bp-padding);
    } @else {
      @include mq($from: $bp-name) {
        @include set-wrapper-padding($bp-padding);
      }
    }
  }
}

// ==========================================================================||
//	1. Allows us to use the layout object on any type of element.
//  2. We need to defensively reset any box-model properties.
//  3. Make flex layout behave like a traditional grid
// ==========================================================================||

@mixin make-layout() {
  align-items: flex-start; /* [3] */
  display: flex; /* [1] */
  flex-wrap: wrap; /* [3] */
  justify-content: flex-start; /* [3] */
  list-style: none; /* [1] */
  margin: 0; /* [2] */
  padding: 0; /* [2] */

  > .o-item {
    @include make-column;
  }
}

// ==========================================================================||
//  Use the negative margin trick for multi-row grids
//  http://csswizardry.com/2011/08/building-better-grid-systems/
//	1. Set layout negative margin based on gutter width
// ==========================================================================||

@mixin set-layout-gutter($gutter) {
  margin-left: -$gutter; /* [1] */

  > .o-item {
    padding-left: $gutter;
  }
}

// ==========================================================================||
//	Calls @include set-layout-gutter across defined breakpoints
// ==========================================================================||

@mixin set-layout-bp-gutter($layout-gutter-map) {
  @each $bp-name, $bp-padding in $layout-gutter-map {
    @if $bp-name == 'mobile' {
      @include set-layout-gutter($bp-padding);
    } @else {
      @include mq($from: $bp-name) {
        @include set-layout-gutter($bp-padding);
      }
    }
  }
}

// ==========================================================================||
//	1. Set column default width
//  2. Apply padding-left for negative margin trick applied to parent
// ==========================================================================||

@mixin make-column() {
  flex: 0 0 100%; /* [1] */
  // Prevent columns from becoming too narrow when at smaller grid tiers by
  // always setting `width: 100%;`. This works because we use `flex` values
  // later on to override this initial width.
  width: 100%; /* [1] */
}

// ==========================================================================||
//	1. Set column padding-left to represent gutters
// ==========================================================================||

@mixin set-column-gutter($gutter) {
  padding-left: $gutter; /* [1] */
}

// ==========================================================================||
//	Requires $layout-bp-gutter to be defined
//
//  Example:
//  $layout-bp-gutter: (
//    mobile: 16px
//  );
// ==========================================================================||

@mixin set-column-bp-gutter($layout-gutter-map) {
  @each $bp-name, $bp-padding in $layout-gutter-map {
    @if $bp-name == 'mobile' {
      @include set-column-gutter($bp-padding);
    } @else {
      @include mq($from: $bp-name) {
        @include set-column-gutter($bp-padding);
      }
    }
  }
}

// ==========================================================================||
//	1. Set column default width
// ==========================================================================||

@mixin set-column-width($size, $columns: 12) {
  flex: 0 0 percentage(math.div($size, $columns)) !important; /* [1] */
  // Add a `max-width` to ensure content within each column does not blow out
  // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
  // do not appear to require this.
  max-width: percentage(math.div($size, $columns)) !important; /* [1] */
}

// ==========================================================================||
//	1. Set column auto width
// ==========================================================================||

@mixin set-column-auto() {
  flex: 1 !important; // QA: HON-52: IE11 not flexing nicely
  max-width: 100% !important;
  width: auto !important;
}

// ==========================================================================||
//	Calculate max-width of wrapper based on no of columns + col width + gap
// ==========================================================================||

@function calc-max-width($columns, $columnsWidth: 1fr, $columnsGap: 8px) {
  @if type-of($columnsWidth) == 'number' and unit($columnsWidth) == 'px' {
    @return ($columns * $columnsWidth) + (($columns - 1) * $columnsGap);
  }
}
