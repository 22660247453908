.c-abbr {
  $this: &;

  // position: absolute;
  // top: 0;
  // right: 0;
  // z-index: 2;
  // width: 100%;

  &__button {
    position: absolute;
    top: 0;
    right: 0;
    width: 120px;
    height: 60px;
    // border-radius: 10px 0 0 10px;
    border-radius: 8px 0 0 8px;
    background-color: $color-primary;
    color: $color-white;
    padding: 0 sp(4);
    transition: background-color $transition-default;
    justify-content: flex-start;

    @include mq($from: desktop) {
      height: 80px;
    }

    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused,
    &:active,
    &.is-active {
      background-color: $color-black;
      text-decoration: underline;
    }

    &.is-open {
      transform: translateX(100%);
      opacity: 0;
    }
  }

  &__wrapper {
    position: fixed;
    top: $header-height--mobile + sp(4);
    right: 0;
    transform: translateX(100%);
    opacity: 0;
    border-radius: 8px 0 0 8px;
    transition: transform $transition-default, opacity $transition-default;
    padding: sp(10) sp(6) sp(3);
    background-color: $color-primary;
    color: $color-white;
    overflow-y: auto;
    width: calc(100% - #{sp(4)});
    max-height: calc(100vh - #{$header-height--mobile} - #{sp(8)});

    @include mq($from: tablet) {
      max-width: 360px;
    }

    @include mq($from: desktop) {
      top: $header-height--desktop;
      max-height: calc(100vh - (#{$header-height--desktop}));

      body.header-is-shrinked & {
        top: $header-height--desktop-shrinked;
        max-height: calc(100vh - (#{$header-height--desktop-shrinked}));
      }
      // max-height: calc(
      //   100vh -
      //     (
      //       #{$header-main-height--desktop} + #{$header-top-height--desktop} + #{$header-secondary-height--desktop}
      //     )
      // );
    }

    &.is-open {
      transform: translateX(0);
      opacity: 1;
    }
  }

  &__close {
    position: absolute;
    top: 12px;
    right: 12px;

    .sprite {
      width: 12px;
      height: 12px;

      svg {
        fill: $color-white;
        width: 12px;
        height: 12px;
      }
    }

    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused {
      .sprite svg {
        // fill: $color-yellow;
      }
    }

    &:active {
      opacity: 0.5;
    }
  }

  //========================================================================||
  //	State (is open)
  //========================================================================||
  // &.is-open {
  //   #{$this}__button {
  //     transform: translateX(100%);
  //     opacity: 0;
  //   }

  //   #{$this}__wrapper {
  //     transform: translateX(0);
  //     opacity: 1;
  //   }
  // }
}
