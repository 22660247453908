$widths-fractions: 1 12 !default;
$widths-offsets: false !default;
$widths-delimiter: $global-delimiter !default;
$widths-separator: $global-separator !default;

@mixin make-width($columns, $breakpoint: null) {
  // Loop through the number of columns for each denominator of our fractions
  @each $denominator in $columns {
    // Begin creating a numerator for our fraction up until $denominator
    @for $numerator from 1 through $denominator {
      // Build a class in the format .u-<numerator>/<denominator>@<breakpoint>
      .u-#{$numerator}#{$widths-delimiter}#{$denominator}#{$breakpoint} {
        @include set-column-width($numerator, $denominator);
      }

      // Create our offsets
      @if ($widths-offsets == true) {
        .o-layout:not(.o-layout--reversed) {
          .o-item.u-offset-#{$numerator}#{$widths-delimiter}#{$denominator}#{$breakpoint} {
            margin-left: math.div($numerator, $denominator) * 100% !important;
          }

          .o-item.u-offset-none#{$breakpoint} {
            margin-left: 0 !important;
          }
        }

        .o-layout.o-layout--reversed {
          .o-item.u-offset-#{$numerator}#{$widths-delimiter}#{$denominator}#{$breakpoint} {
            margin-right: math.div($numerator, $denominator) * 100% !important;
          }

          .o-item.u-offset-none#{$breakpoint} {
            margin-right: 0 !important;
          }
        }
      }
    }
  }

  .u-auto-width#{$breakpoint} {
    @include set-column-auto;
  }
}

@include make-width($widths-fractions);

@if (variable-exists(mq-breakpoints)) {
  @each $bp-name, $bp-value in $mq-breakpoints {
    @include mq($from: $bp-name) {
      @include make-width($widths-fractions, #{$widths-separator}#{$bp-name});
    }
  }
}
