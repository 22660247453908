$list-item-gap: 16px !default;

@mixin reset-list() {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin make-list-inline() {
  font-size: 0;
  line-height: 0;

  > li,
  > .o-item {
    display: inline-block;
  }
}

@mixin make-list-bare() {
  > li,
  > .o-item {
    display: block;
  }
}
