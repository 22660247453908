@mixin c-button-secondary() {
  border-radius: $border-radius-default;
  border: 1px solid $color-primary;
  background-color: transparent;
  color: $color-primary;
  padding: 12px 24px;
  min-height: 44px;

  .sprite svg {
    fill: $color-primary;
  }

  &:hover,
  &.is-hovered {
    &,
    &:focus,
    &.is-focused {
      border-color: $color-black;
      color: $color-black;

      .sprite svg {
        fill: $color-black;
      }
    }
  }

  &:focus,
  &.is-focused {
    border-color: $color-bgblue;
    color: $color-bgblue;

    .sprite svg {
      fill: $color-bgblue;
    }
  }

  &:active,
  &.is-active {
    border-color: $color-black;
    color: $color-black;

    .sprite svg {
      fill: $color-black;
    }
  }

  &:disabled,
  &.is-disabled {
    border-color: $color-disabled;
    color: $color-disabled;

    .sprite svg {
      fill: $color-disabled;
    }
  }
}

.c-button-secondary {
  @include c-button-secondary;
}
