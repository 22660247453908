// ==========================================================================||
//	To enable/disable interaction with pointer-events/touch-action
//
//  Usage:
//  @include interaction(true);
// ==========================================================================||

@mixin interaction($enabled, $important: false) {
  $impt: false;

  // Thanks inuitcss!
  @if ($important == true) {
    $important: !important;
    $impt: true;
  } @else if ($important == false) {
    $important: null;
    $impt: false;
  } @else {
    @error "$important needs to be 'true' or 'false'.";
  }

  @if $enabled == true {
    pointer-events: auto $important;
    touch-action: auto $important;
  } @else {
    pointer-events: none $important;
    touch-action: none $important;
  }
}

// ==========================================================================||
//	To force element to be single line
//
//  Usage:
//  @include single-line;
// ==========================================================================||

@mixin single-line() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// ==========================================================================||
//	Add animated loader
// ==========================================================================||

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotation-centered {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@mixin loader($color: $color-black) {
  height: 32px;
  width: 32px;

  &:before {
    content: '';

    animation: rotation 2s infinite linear;
    background-image: url(icon-loader($color));
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: block;
    height: 100%;
    width: 100%;
  }
}

// ==========================================================================||
//	str-replace
// ==========================================================================||

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}

// ==========================================================================||
//	svg-encode
//  - Encodes SVG markup to use inline
// ==========================================================================||
//  Usage:
//  @return svg-encode('<svg></svg>'); | no-linebreaks
// ==========================================================================||

$encoding-reference: (
  ('%', '%25'),
  ('<', '%3C'),
  ('>', '%3E'),
  (' ', '%20'),
  ('!', '%21'),
  ('*', '%2A'),
  ("'", '%27'),
  ('"', '%22'),
  ('(', '%28'),
  (')', '%29'),
  (';', '%3B'),
  (':', '%3A'),
  ('@', '%40'),
  ('&', '%26'),
  ('=', '%3D'),
  ('+', '%2B'),
  ('$', '%24'),
  (',', '%2C'),
  ('/', '%2F'),
  ('?', '%3F'),
  ('#', '%23'),
  ('[', '%5B'),
  (']', '%5D')
);

@function svg-encode($svg) {
  @each $char, $encoded in $encoding-reference {
    $svg: str-replace($svg, $char, $encoded);
  }

  @return 'data:image/svg+xml,' + $svg;
}

@function map-has-values($map) {
  // @return length(map-keys($map)) > 0;
  @return true;
}

// $wrapper: fullbleed|inner
@function hf-column-calc(
  $columns,
  $gutters,
  $breakpoint: 'desktop',
  $mode: 'fullbleed',
  $offset: '0px'
) {
  @if ($mode == 'fullbleed') {
    @return calc(
      (
          #{$columns} * (1 / 12 *
                (
                  100vw - (2 * #{map-get($wrapper-padding, $breakpoint)}) -
                    (11 * #{map-get($layout-gutter, $breakpoint)})
                ))
        ) + (#{$gutters} * #{map-get($layout-gutter, $breakpoint)}) +
        (#{$offset})
    );
  } @else if ($mode == 'outer') {
    @return calc(
      (
          #{$columns} * (1 / 12 *
                (
                  #{map-get($wrapper-max-width, $breakpoint)} - (
                      2 * #{map-get($wrapper-padding, $breakpoint)}
                    ) - (11 * #{map-get($layout-gutter, $breakpoint)})
                ))
        ) + (#{$gutters} * #{map-get($layout-gutter, $breakpoint)}) +
        (#{$offset})
    );
  }
}
