// ==========================================================================||
//  Spacings
//  Should be a multiplier of $global-baseline as the letter increments
// ==========================================================================||

$spacings: (
  'none': 0px,
  'px': 1px,
);

$spacings-list: (
  0.5 1 1.5 2 2.5 3 3.5 4 5 6 7 8 9 10 11 12 14 15 16 20 21 24 28 30 32 36 40 44
    48 52 56 60 64 72 80 96
);

@each $sp in $spacings-list {
  @if not map-has-key($spacings, $sp) {
    $spacings: map-merge(
      $spacings,
      (
        $sp: $sp * 4px,
      )
    );
  }
}

@function sp($sp-name) {
  @return map-get($spacings, $sp-name);
}

@function -sp($sp-name) {
  @return -1 * map-get($spacings, $sp-name);
}
