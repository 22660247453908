// ==========================================================================||
//	Image Elements
// ==========================================================================||

// Used in conjunction with parent element having a o-ratio constrained
// Utilizes object-fit: cover rule to render image with a background-image
// fallback in case object-fit is not supported on the current browser

// Example Usage
// ==========================================================================||
//	<div class="o-ratio  o-ratio--16:9">
//    <figure class="o-ratio__content  image">
//      <img />
//    </figure>
//  </div>
// ==========================================================================||

.image {
  display: block;
  height: 100%;
  width: 100%;
  margin: 0;

  /* remove as not supporting IE11 anymore
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  html.objectfit & {
    background: none !important;
  }

  img {
    display: none;

    html.objectfit & {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .content & {
      height: 100% !important;
    }
  }
  */

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;

    .content & {
      height: 100% !important;
    }
  }
}

.image-block {
  display: block;

  img {
    display: block;
    height: auto;
    width: 100%;
  }
}
