.accordion {
  $this: &;
  // border-bottom: 1px solid $color-white;
  // border-top: 1px solid $color-white;
  box-sizing: content-box; // so padding/border dont effect sizing
  color: inherit;
  transition: max-height $transition-default $transition-duration;

  &__body {
    @include interaction(false);
    box-sizing: border-box; // overwrite .c-accordion content-box
    opacity: 0;
    overflow: hidden;
    padding: 0; // must always be 0
    transition: opacity $transition-default;
    width: 100%;
  }

  // set padding for styling here
  // or apply o-panel classes
  &__content {
  }

  //========================================================================||
  //	States
  //========================================================================||
  &.is-open {
    transition: max-height $transition-default;

    > #{$this}__body {
      @include interaction(true);
      opacity: 1;
      transition: opacity $transition-default $transition-duration;
    }
  }
}
