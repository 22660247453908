.card {
  display: block;
  border-radius: $border-radius-default;
  border: 1px solid $color-primary;
  background-color: $color-white;
  color: $color-black;
  transition-property: color, background-color, border-color;
  transition-duration: $transition-duration;
  transition-timing-function: $transition-easing;
  text-decoration: none;
  height: 100%;

  &:focus,
  &.is-focused {
    outline: none;
  }

  &:disabled,
  &.is-disabled {
    @include interaction(false);
    border-color: $color-disabled !important;
    color: $color-disabled !important;
  }
}
