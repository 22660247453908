.sr-only,
.u-sr,
.u-hidden-visually {
  @include hf-hidden-visually();
}

.u-pr {
  position: relative;
}

.u-pr1 {
  position: relative;
  z-index: 1;
}

.u-overflow-hidden {
  overflow: hidden;
}

.u-clearfix {
  @include hf-clearfix();
}

// ==========================================================================||
//  Spacing utility for special cases
// ==========================================================================||

.u-inset {
  @include mq($until: desktop) {
    padding-left: sp(4);
    padding-right: sp(4);
  }
}
