.content {
  $this: &;
  color: $color-text-tlight;

  * {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  h3 {
    @include sf-typography('h3');
  }

  // h4 {
  //   @include sf-typography('h4');
  // }

  // h5 {
  //   @include sf-typography('h5');
  // }

  // h6 {
  //   @include sf-typography('h6');
  // }

  h3,
  h4,
  h5,
  h6 {
    // font-weight: $font-weight-medium;

    &:not(:first-child) {
      margin-top: sp(12);
    }

    &:not(:last-child) {
      margin-bottom: sp(6);
    }
  }

  ul,
  ol {
    @include reset-list;
    @include make-list-bare;
    margin: sp(3) 0;

    > li {
      position: relative;
      margin-bottom: sp(3);
      padding-left: 0;

      &:before {
        display: inline-block;
        vertical-align: middle;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ol {
    counter-reset: section;

    > li {
      &:before {
        content: counter(section, decimal) '.';
        color: inherit;
        counter-increment: section;
        font-weight: $font-weight-medium;
        margin-right: sp(1);
      }
    }
  }

  ul {
    > li {
      &:before {
        content: ' ';

        background-image: url(icon-list-dot($color-text-tlight));
        background-repeat: no-repeat;
        background-size: cover;
        height: 5px;
        width: 5px;
        margin-right: sp(2);
      }

      // second level - circle
      // ul {}
      // ul > li {}

      // third level - square
      // ul > li ul {}
      // ul > li ul > li {}
    }
  }

  p,
  dl,
  ol,
  ul,
  table {
    &:not(:first-child) {
      margin-top: sp(3);
    }

    &:not(:last-child) {
      margin-bottom: sp(3);
    }
  }

  a:not([class]) {
    @extend .link;
    @extend .link--underline;
    color: $color-primary;

    &:hover,
    &.is-hovered {
      color: $color-purple;
    }
  }

  em {
    font-style: normal;
    color: $color-purple;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;

    &:not([style*='float']) {
      width: 100% !important;
    }
  }

  img[style*='float: left']:not([style*='margin']) {
    margin: 0 sp(3) sp(3) 0;
  }

  img[style*='float: right']:not([style*='margin']) {
    margin: 0 0 sp(3) sp(3);
  }

  figure {
    display: block;
    width: 100%;
    margin: sp(8) auto;

    @include mq($from: desktop) {
      width: 100%;
      margin: sp(10) 0;
    }

    figcaption {
      @include sf-typography('body-sm');
      margin-top: sp(2);
      padding: 0 map-get($wrapper-padding, 'mobile');

      @include mq($from: desktop) {
        padding: 0;
      }
    }
  }

  iframe {
    width: 100% !important;
    max-width: 100% !important;
    border: none;
    border-radius: 4px;
    display: block;
    margin: 0 auto !important;
  }

  table {
    table-layout: fixed;
    width: 100%;

    thead > tr > th,
    tbody > tr > td {
      padding: sp(2) sp(2.5);
    }

    tbody {
      tr {
        border-top: 1px solid $color-primary;
        border-bottom: 1px solid $color-primary;

        + tr {
          border-top: none;
        }
      }
    }
  }

  sup.footnote > a {
    text-decoration: none;
  }

  // hide the rest
  blockquote,
  pre,
  dl,
  dd,
  // sup,
  sub {
    display: none;
  }

  // ==========================================================================||
  //  Variation
  // ==========================================================================||

  // Supports CSS grid for <ol>
  // &.supports-grid-ol {
  //   @include mq($from: desktop) {
  //     ol {
  //       display: grid;
  //       grid-template-rows: repeat(5, 1fr);
  //       grid-column-gap: 24px;
  //       grid-row-gap: 4px;
  //       grid-auto-flow: column;

  //       li {
  //         margin-bottom: 0;
  //       }
  //     }
  //   }
  // }

  // ==========================================================================||
  //  Theme Variation
  // ==========================================================================||

  .t-dark & {
    color: $color-text-tdark;

    ol > li:before {
      color: $color-text-tdark;
    }

    ul > li:before {
      background-image: url(icon-list-dot($color-text-tdark));
    }
  }

  // ==========================================================================||
  //  Text Color Variation (with utility classes)
  //  Supports primary only
  // ==========================================================================||

  &.u-text-color-primary {
    ul > li:before {
      background-image: url(icon-list-dot($color-primary));
    }
  }
}
