.c-cta {
  $this: &;

  &__buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    > .button {
      margin-right: sp(4);
      margin-top: sp(4);

      &:last-child {
        margin-right: 0;
      }

      @include mq($from: desktop) {
        margin-right: sp(10);
        margin-top: sp(9);
      }
    }
  }

  // ==========================================================================||
  //  Alignments
  // ==========================================================================||

  &.is-left {
    #{$this}__buttons {
      justify-content: flex-start;
    }
  }

  &.is-center {
    #{$this}__buttons {
      justify-content: center;
    }
  }

  &.is-right {
    #{$this}__buttons {
      justify-content: flex-end;
    }
  }
}
