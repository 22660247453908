// ==========================================================================||
//	Color settings
// ==========================================================================||

/* stylelint-disable color-no-hex, color-named */

$color-honestfox: #ff3d2e;

// Base colors

$color-black: #353535;
$color-white: #ffffff;
$color-light: #dbdbdb;
$color-placeholder: #333333;

// Colors based on styleguide
// Brand
$color-primary: #005da8;
$color-secondary: #1597bb;
$color-bgblue: #004175;
$color-yellow: #ffee58;
$color-purple: #ab47bd;

// Neutrals
$color-grey: #ecedee;
$color-midgrey: #c3c4c3; // button/card inactive
$color-lightgrey: #f5f5f5;

// System
$color-error: #f2453d;
$color-success: #4cce51;
$color-warning: #f29e1f;
$color-disabled: $color-midgrey;
$color-focus: $color-primary;

/* stylelint-enable color-no-hex, color-named */

// ==========================================================================||
//	Namespaces
// ==========================================================================||

$color-text-tlight: $color-black;
$color-text-tdark: $color-white;

$color-accent-tlight: $color-black;
$color-accent-tdark: $color-white;
