// h1 {
//   @include sf-typography('h1');
// }

// h2 {
//   @include sf-typography('h2');
// }

// h3 {
//   @include sf-typography('h3');
// }

// h4 {
//   @include sf-typography('h4');
// }

// h5 {
//   @include sf-typography('h5');
// }

// h6 {
//   @include sf-typography('h6');
// }

b,
strong {
  font-weight: $font-weight-semibold;
}

i,
em {
  font-style: italic;
}

small {
  @include sf-typography('body-sm');
}
