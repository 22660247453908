.c-question-group {
  + .c-question-group {
    padding-top: sp(12);

    @include mq($from: desktop) {
      padding-top: sp(15);
    }
  }
}

.c-question {
  $this: &;

  + .c-question {
    padding-top: sp(6);
  }

  &__desc {
    display: flex;
    align-items: flex-end;
  }

  &__answers {
    padding-top: sp(2);

    @include mq($from: tablet) {
      padding-top: 0;
    }
  }

  &__options {
    @include mq($from: tablet) {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
    }
  }

  &__option.c-radio {
    @include mq($until: tablet) {
      display: block;
      margin-bottom: sp(4);

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include mq($from: tablet) {
      position: relative;
      flex: 1;
      max-width: 100px; // qa: so long labels dont ruin the layout too much
      text-align: center;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  // ==========================================================================||
  //  Flag Variation
  // ==========================================================================||
  &.has-heading {
    #{$this}__content {
      @include mq($from: desktop) {
        padding-top: sp(8);
      }
    }
  }
}

.c-radio {
  display: inline-block;
  position: relative;

  label {
    @include sf-typography('body');
    display: inline-block;
    margin: 0;
    cursor: pointer;
    color: $color-primary;

    @include mq($until: tablet) {
      padding-left: sp(8);
    }

    @include mq($from: tablet) {
      padding-bottom: sp(8);
    }

    &:before,
    &:after {
      content: ' ';

      position: absolute;
      
      border-radius: 50%;
      backface-visibility: hidden;

      @include mq($until: tablet) {
        top: 0;
        left: 0;
      }

      @include mq($from: tablet) {
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &:before {
      width: 24px;
      height: 24px;
      border: 1px solid $color-black;
      transition: border-color $transition-default,
        background-color $transition-default;
      will-change: border-color, background-color;
    }

    &:after {
      width: 18px;
      height: 18px;
      border: 1px solid transparent;
      background-color: transparent;
      transition: border-color $transition-default,
      background-color $transition-default;
      will-change: border-color, background-color;

      @include mq($until: tablet) {
        top: 3px;
        left: 3px;
      }
      
      @include mq($from: tablet) {
        bottom: math.div(24px - 18px, 2);
      }
    }
  }

  input {
    @include hf-hidden-visually();
    appearance: none;

    &:checked + label {
      &:before {
        border-color: $color-primary;
      }

      &:after {
        border-color: $color-primary;
        background-color: $color-primary;
      }
    }

    &:focus + label {
      &:before {
        border-color: $color-primary;
      }
    }

    &:disabled:not(:checked) + label {
      &:before {
        border-color: $color-disabled;
      }

      &:after {
        border-color: $color-disabled;
        background-color: $color-disabled;
      }
    }
  }

  &.is-hovered,
  &:hover {
    label {
      &:before {
        border-color: $color-primary;
      }

      &:after {
        border-color: $color-primary;
      }
    }

    input:checked + label {
      &:before {
        border-color: $color-primary;
      }

      &:after {
        background-color: $color-primary;
      }
    }
  }
}
