// ==========================================================================||
//	objects/objects.wrapper
// wrapper-max-width = content area + padding left/right
// ==========================================================================||

$wrapper-fullbleed-max-width: 1920px;
$wrapper-outer-max-width: 1288px;

$wrapper-max-width: (
  desktop: 1288px,
);

$wrapper-padding: (
  mobile: sp(4),
  tablet: sp(6),
  desktop: sp(10),
);

// ==========================================================================||
//	objects/objects.layout
// ==========================================================================||

$layout-gutter: (
  mobile: sp(6),
  tablet: sp(4),
  desktop: sp(10),
);

// ==========================================================================||
//	utilities/utilities.widths
// ==========================================================================||

$widths-fractions: 1 8 12;
$widths-offsets: true;
