// ==========================================================================||
//	Usage:
//  <ul class="o-list  o-list--inline"> or <ul class="o-list  o-list--bare">
//    <li class="o-item"></li>
//  </ul>
// ==========================================================================||

.o-list {
  @include reset-list;

  &--inline {
    @include make-list-inline;
  }

  &--bare {
    @include make-list-bare;
  }
}
