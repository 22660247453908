// Based off styleguide
$heebo: 'Heebo', sans-serif;

// ==========================================================================||
//	font-weight notes
// ==========================================================================||

$font-weight-black: 900;
$font-weight-extrabold: 800;
$font-weight-bold: 700;
$font-weight-semibold: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;
$font-weight-light: 300;
$font-weight-xlight: 200;
$font-weight-thin: 100;

// ==========================================================================||
//	Fonts included in $fonts will be used in utilities
// ==========================================================================||

$fonts: (
  'heebo': $heebo,
);

// prettier-ignore
$typographies: (
  'body': (
    font: 'heebo',
    weight: $font-weight-regular,
    breakpoints: (
      mobile: (16px, 24px, 0), // 0.5%
    ),
  ),
  'body-sm': (
    font: 'heebo',
    weight: $font-weight-regular,
    breakpoints: (
      mobile: (14px, 20px, 0),
    ),
  ),
  'h1': (
    font: 'heebo',
    weight: $font-weight-bold,
    breakpoints: (
      mobile: (40px, 50px, 0),
      desktop: (72px, 84px, 0),
    ),
  ),
  'h2': (
    font: 'heebo',
    weight: $font-weight-bold,
    breakpoints: (
      mobile: (36px, 48px, 0),
      desktop: (56px, 63px, 0),
    ),
  ),
  'h3': (
    font: 'heebo',
    weight: $font-weight-semibold,
    breakpoints: (
      mobile: (30px, 40px, 0),
      desktop: (40px, 56px, 0),
    ),
  ),
  'h4': (
    font: 'heebo',
    weight: $font-weight-semibold,
    breakpoints: (
      mobile: (26px, 32px, 0),
      desktop: (32px, 46px, 0),
    ),
  ),
  'h5': (
    font: 'heebo',
    weight: $font-weight-semibold,
    breakpoints: (
      mobile: (24px, 32px, 0),
      desktop: (28px, 34px, 0),
    ),
  ),
  'h6': (
    font: 'heebo',
    weight: $font-weight-medium,
    breakpoints: (
      mobile: (20px, 28px, 0),
      desktop: (22px, 32px, 0),
    ),
  ),
  'caption': (
    font: 'heebo',
    weight: $font-weight-regular,
    breakpoints: (
      mobile: (12px, 16px, 0),
    ),
  ),
  'button': (
    font: 'heebo',
    weight: $font-weight-regular,
    breakpoints: (
      mobile: (14px, 18px, 0),
    ),
  ),
  /*
  "form-input":(
    font: "heebo",
    weight: $font-weight-regular,
    breakpoints: (
      mobile: (13px, 20px, 0.5px),
    ),
  ),
  "form-label": (
    font: "heebo",
    weight: $font-weight-regular,
    breakpoints: (
      mobile: (10px, 14px, 0.5px),
    ),
  ),
  "form-checkbox-label": (
    font: "heebo",
    weight: $font-weight-regular,
    breakpoints: (
      mobile: (13px, 20px, 0.5px),
    ),
  ),
  "form-error": (
    font: "heebo",
    weight: $font-weight-regular,
    breakpoints: (
      mobile: (13px, 20px, 0.5px),
    ),
  ),
  */
);
