$styleguide-colors: (
  'black': $color-black,
  'white': $color-white,
  'primary': $color-primary,
  'secondary': $color-secondary,
  'bgblue': $color-bgblue,
  'yellow': $color-yellow,
  'purple': $color-purple,
  'grey': $color-grey,
  'midgrey': $color-midgrey,
  'error': $color-error,
  'success': $color-success,
  'warning': $color-warning,
  'focus': $color-focus,
  'disabled': $color-disabled,
);

.page-styleguide {
  background-color: $color-white;
  color: $color-black;

  main > section {
    position: relative;
    padding: 30px + sp(8) 0 sp(8);
    border-bottom: 1px solid $color-black;
    background-color: $color-white;

    @include mq($from: desktop) {
      padding: 30px + sp(12) 0 sp(12);
    }

    &:first-child {
      border-top: 1px solid $color-black;
    }
  }

  section.styleguide-sprites .sprite {
    width: 40px;
    height: 40px;

    svg {
      width: 40px;
      height: 40px;
      fill: $color-black;
    }
  }
}

.styleguide__title {
  @include sf-font-size(16px, 22px);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: block;
  padding: sp(1) sp(2);
  background-color: $color-black;
  color: $color-white;
}

.styleguide__notes {
  @include sf-font-size(12px, 16px);
  content: none;

  position: absolute;
  top: 0;
  left: 0;
  display: block;
  padding: sp(1) sp(2);
  background-color: $color-black;
  color: $color-white;

  @include mq($from: desktop) {
    top: 12px;
  }
}

.styleguide__item {
  width: 100%;
  height: 32px;
  background-color: $color-black;

  @each $color-namespace, $color-hex in $styleguide-colors {
    &.bg-#{$color-namespace} {
      border: 1px solid rgba($color-black, 0.2);
      background-color: $color-hex;
      cursor: pointer;

      &:before,
      &:after {
        @include sf-font-size(12px, 16px);
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        transition: opacity $transition-default;
      }

      &:before {
        content: '#{$color-namespace}';

        bottom: 100%;
        padding-bottom: sp(1);
      }

      &:after {
        content: '#{$color-hex}';

        position: absolute;
        top: 100%;
        padding-top: sp(1);
      }

      &:hover {
        &:before,
        &:after {
          opacity: 1;
        }
      }
    }
  }
}

.styleguide__layout {
  margin-bottom: -sp(6) !important;

  .o-item {
    margin-bottom: sp(6);
  }
}

.styleguide__padded-item {
  padding-bottom: 100%;
  background-color: $color-black;
}

.styleguide__spacer {
  height: sp(6);

  @include mq($from: desktop) {
    height: sp(12);
  }
}

.styleguide__typography {
  * {
    margin-top: sp(4);
    margin-bottom: sp(4);

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
