$sprite-default-size: sp(8) !default;

// prettier-ignore
$sprite-sizes: () !default;

// prettier-ignore
$sprite-colors: (
  "default": $color-black,
) !default;

.sprite {
  align-items: center;
  backface-visibility: hidden;
  display: inline-flex;
  height: $sprite-default-size;
  justify-content: center;
  transition: all $transition-default;
  vertical-align: middle;
  width: $sprite-default-size;

  svg {
    display: block;
    fill: $color-black;
    height: $sprite-default-size;
    transition: transform $transition-default, fill $transition-default;
    width: $sprite-default-size;
  }

  // ==========================================================================||
  //  Size Variants
  //  based on $sprite-sizes map
  //
  //  Usage:
  //  {{ sprite('icon-arrow', 'is-large', '0 0 32 32') }}
  // ==========================================================================||

  @each $size-namespace, $size-value in $sprite-sizes {
    &.is-#{$size-namespace} {
      &,
      svg {
        height: $size-value;
        width: $size-value;
      }
    }
  }

  // ==========================================================================||
  //	Color Variants
  //  based on $sprite-colors map
  //
  //  Usage:
  //  {{ sprite('icon-arrow', 'color-black', '0 0 32 32') }}
  // ==========================================================================||

  @each $color-namespace, $color-hex in $sprite-colors {
    &.color-#{$color-namespace} {
      svg {
        fill: $color-hex;
      }
    }
  }
}
