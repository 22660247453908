@use 'sass:math';

// ==========================================================================||
//	Settings
// ==========================================================================||

@import './settings/_settings.core.scss';
@import './settings/_settings.colors.scss';
@import './settings/_settings.spacings.scss';
@import './settings/_settings.globals.scss';
@import './settings/_settings.breakpoints.scss';
@import './settings/_settings.typography.scss';
@import './settings/_settings.grid.scss';
@import './settings/_settings.panel.scss';
@import './settings/_settings.ratio.scss';
// @import './settings/_settings.objects.scss';
// @import './settings/_settings.elements.scss';
@import './settings/_settings.utilities.scss';
// @import './settings/_settings.z-index.scss';
@import './settings/_settings.hamburger.scss';

// ==========================================================================||
//	Tools
// ==========================================================================||

// sass-mq
@import '~sass-mq/_mq.scss';

// sass-fonts
@import '~sass-fonts/tools/_tools.family.scss';
@import '~sass-fonts/tools/_tools.typography.scss';
@import '~sass-fonts/tools/_tools.font-size.scss';

// hamburgers
@import '~hamburgers/_sass/hamburgers/base.scss';
@import '~hamburgers/_sass/hamburgers/types/elastic.scss';

// local
@import './tools/_tools.core.scss';
@import './tools/_tools.math.scss';
@import './tools/_tools.grid.scss';
@import './tools/_tools.helpers.scss';
@import './tools/_tools.list.scss';
@import './tools/_tools.panel.scss';
@import './tools/_tools.position.scss';
@import './tools/_tools.transition.scss';
@import './tools/_tools.icons.scss';

// ==========================================================================||
//	Generic
// ==========================================================================||

@import './generic/_generic.box-sizing.scss';
@import './generic/_generic.normalize.scss';
@import './generic/_generic.reset.scss';
@import './generic/_generic.styleguide.scss';

// ==========================================================================||
//	Elements
// ==========================================================================||

@import './elements/_elements.page.scss';
@import './elements/_elements.typography.scss';
@import './elements/_elements.sprite.scss';
@import './elements/_elements.button.scss';
@import './elements/_elements.anchor.scss';
@import './elements/_elements.image.scss';
@import './elements/_elements.content.scss';
// @import './elements/_elements.swiper.scss';
@import './elements/_elements.accordion.scss';
// @import './elements/_elements.hamburgers.scss';
// @import './elements/_elements.misc.scss';
@import './elements/_elements.card.scss';
@import './elements/_elements.hamburger.scss';

// ==========================================================================||
//	Objects
// ==========================================================================||

@import './objects/_objects.wrapper.scss';
@import './objects/_objects.layout.scss';
@import './objects/_objects.panel.scss';
@import './objects/_objects.list.scss';
@import './objects/_objects.ratio.scss';

// ==========================================================================||
//  Components
// ==========================================================================||
@import './components/_components.button-primary.scss';
@import './components/_components.button-secondary.scss';
@import './components/_components.button-tertiary.scss';
@import './components/_components.card-primary.scss';
@import './components/_components.card-flow.scss';
@import './components/_components.card-link.scss';
@import './components/_components.card-result.scss';
@import './components/_components.card-profile.scss';
@import './components/_components.references.scss';
@import './components/_components.cta.scss';
@import './components/_components.header.scss';
@import './components/_components.header-menu.scss';
@import './components/_components.footer.scss';
@import './components/_components.search.scss';
@import './components/_components.hero.scss';
@import './components/_components.questionnaire.scss';
@import './components/_components.divider.scss';
@import './components/_components.abbreviations.scss';
@import './components/_components.pagination.scss';

// ==========================================================================||
//	Utilities
// ==========================================================================||

@import './utilities/_utilities.bg-colors.scss';
@import './utilities/_utilities.typography.scss';
@import './utilities/_utilities.showhide.scss';
@import './utilities/_utilities.misc.scss';
@import './utilities/_utilities.text.scss';
@import './utilities/_utilities.widths.scss';
@import './utilities/_utilities.spacings.scss';

// https://medium.com/vuejs-tips/v-cloak-45a05da28dc4
[v-cloak] > * {
  display: none;
}
