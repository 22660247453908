html,
body {
  overflow-x: hidden;
}

html {
  @include sf-family('heebo');
  color: $color-text-tlight;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  @include sf-typography('body');
  background-color: $color-white;

  // lock body from scrolling
  &.no-scroll {
    position: fixed;
    right: 0;
    left: 0;
    overflow: hidden;
  }
}
