// TODO: Add comments

@mixin set-panel-padding($modifier, $padding) {
  &--#{$modifier} {
    padding-top: $padding;
    padding-bottom: $padding;
  }

  &-top--#{$modifier} {
    padding-top: $padding;
  }

  &-bottom--#{$modifier} {
    padding-bottom: $padding;
  }
}

// TODO: Add comments

@mixin set-panel-bp-padding($panel-modifiers) {
  @each $mod-name, $mod-value in $panel-modifiers {
    @if map-has-key($map: $mod-value, $key: 'breakpoints') {
      $breakpoints: map-get($mod-value, 'breakpoints');

      @each $bp-name, $bp-padding in $breakpoints {
        @if $bp-name == 'mobile' {
          @include set-panel-padding($mod-name, $bp-padding);
        } @else {
          @include mq($from: $bp-name) {
            @include set-panel-padding($mod-name, $bp-padding);
          }
        }
      }
    }
  }
}
