$header-height--mobile: 64px;
$header-height--desktop: 160px;
$header-height--desktop-shrinked: 88px;

body {
  padding-top: $header-height--mobile;

  @include mq($from: desktop) {
    padding-top: $header-height--desktop;
  }
}

.c-header {
  $this: &;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  transform: translateY(0);

  &__main {
    position: relative;
    z-index: 2;
    background-color: $color-primary;
  }

  &__wrapper {
    display: flex;
    align-items: flex-start;
    height: $header-height--mobile;
    transition: height $transition-default;

    @include mq($from: desktop) {
      height: $header-height--desktop;
      padding-left: 117px; // 117 = width of logo
      padding-right: 120px;
    }

    @include mq($from: wide) {
      padding-right: 200px; // 200 = width of search field
    }
  }

  &__brand {
    position: absolute;
    top: math.div($header-height--mobile - 22px, 2);
    left: 0;

    @include mq($from: desktop) {
      top: math.div($header-height--desktop - 117px, 2);
    }

    svg {
      display: block;
      width: 72px;
      height: 22px;
      // transition: all $transition-default;

      @include mq($from: desktop) {
        width: 117px;
        height: 117px;
      }
    }
  }

  &__menu-btn {
    padding: math.div($header-height--mobile - 32px, 2) 0;
    margin-left: auto;
  }

  &__brand,
  &__menu-btn {
    @include mq($until: desktop) {
      transition: opacity $transition-default $transition-duration-slower;
    }
  }

  &__search {
    position: absolute;
    z-index: 1;
    transition: all $transition-slower;
    top: math.div($header-height--mobile - 40px, 2);

    @include mq($until: tablet) {
      width: 120px;
      left: calc((100vw - 120px) / 2);
    }

    @include mq($from: tablet, $until: desktop) {
      width: 200px;
      left: calc((100vw - 200px) / 2);
    }

    @include mq($from: desktop) {
      width: 120px;
      top: math.div($header-height--desktop - 40px, 2);
      right: 0;
    }

    @include mq($from: wide) {
      width: 200px;
    }
  }

  &__nav {
    @include mq($from: desktop) {
      margin-left: auto;
      margin-right: auto;

      ul.level-1 {
        @include reset-list;
        @include make-list-inline;

        > li {
          margin-right: sp(6);

          &:last-child {
            margin-right: 0;
          }

          &.has-children {
            position: relative;
            padding: 68px 0;
            cursor: pointer;
          }
        }
      }

      ul.level-2 {
        @include reset-list;
        @include make-list-bare;
        @include interaction(false);
        position: absolute;
        top: 100%;
        left: -sp(6);
        padding: sp(6);
        background-color: $color-primary;
        transform: translateY(-25px);
        opacity: 0;
        transition: opacity $transition-default, transform $transition-default;
        max-height: calc(100vh - #{$header-height--desktop});
        overflow: auto;

        > li {
          margin-bottom: sp(3);

          &:last-child {
            margin-bottom: 0;
          }
        }

        > li > a {
          // @include sf-typography('body');
          white-space: nowrap;

          &:before {
            background-color: $color-yellow;
          }
        }
      }

      ul.level-1 > li:hover,
      ul.level-1 > li:focus-within {
        ul.level-2 {
          @include interaction(true);
          transform: translateY(0);
          opacity: 1;
        }
      }

      ul > li.is-disabled > .link {
        color: rgba($color-white, 0.3);
      }
    }
  }

  &__nav .link {
    position: relative;
    color: $color-white;
    text-decoration: none;

    &:before {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 0;
      height: 1px;
      background-color: $color-white;
      transition: width $transition-default;
    }

    &[href]:hover,
    &[href].is-hovered,
    &[href]:focus,
    &[href].is-focused,
    &[href]:active,
    &[href].is-active {
      &:before {
        width: 100%;
      }
    }
  }

  &__nav ul.level-1 > li.has-children:hover > .link,
  &__nav ul.level-1 > li.has-children:focus-within > .link {
    &:before {
      width: 100%;
    }
  }

  // ==========================================================================||
  //  States
  // ==========================================================================||
  &.is-shrinked {
    @include mq($from: desktop) {
      transition: transform $transition-default;

      #{$this}__wrapper {
        height: $header-height--desktop-shrinked;
      }

      #{$this}__brand {
        top: math.div($header-height--desktop-shrinked - 35px, 2);

        svg {
          width: 110px;
          height: 35px;
        }
      }

      #{$this}__search {
        top: math.div($header-height--desktop-shrinked - 40px, 2);
      }

      #{$this}__nav {
        ul.level-1 {
          > li.has-children {
            padding: 32px 0;
          }
        }
      }
    }
  }

  &.is-searching {
    #{$this}__wrapper {
      height: 100vh;
    }

    #{$this}__search {
      @include mq($until: desktop) {
        left: 0;
        width: 100%;
      }

      @include mq($from: desktop) {
        width: 580px;
        right: calc(50% - 290px);
        transition: all $transition-slower 200ms;
      }
    }

    #{$this}__brand,
    #{$this}__menu-btn {
      @include mq($until: desktop) {
        opacity: 0;
        transition: opacity $transition-default;
      }
    }
  }
}
