// ==========================================================================||
//	To position center elements in a 'position:relative' parent element
//
//  Usage:
//  @include position-center;
// ==========================================================================||

@mixin position-center() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// ==========================================================================||
//	To position elements horizontally centered in a 'position:relative' parent element
//
//  Usage:
//  @include position-center-horizontally;
// ==========================================================================||

@mixin position-center-horizontally() {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

// ==========================================================================||
//	To position elements vertically centered in a 'position:relative' parent element
//
//  Usage:
//  @include position-center-vertically;
// ==========================================================================||

@mixin position-center-vertically() {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

// ==========================================================================||
//	To position elements to borders of parent element
//
//  Usage:
//  @include position-fill;
// ==========================================================================||

@mixin position-fill() {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
