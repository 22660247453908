// ==========================================================================||
//	Core Mixins
//  Port over from inuitcss, these mixins are used for core functions
//  and should not be removed.
// ==========================================================================||

// ==========================================================================||
//	micro clearfix solution into a selector
//  http://www.cssmojo.com/the-very-latest-clearfix-reloaded/
//
//  Usage:
//  .class {
//    @include clearfix;
//  }
// ==========================================================================||

@mixin hf-clearfix() {
  &:after {
    content: '' !important;
    clear: both !important;
    display: block !important;
  }
}

// ==========================================================================||
//	inuitcss hidden-visually solution to hide elements but keep it accessible
// ==========================================================================||

@mixin hf-hidden-visually() {
  position: absolute !important;
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  white-space: nowrap !important;
  width: 1px !important;
}

// ==========================================================================||
//  button reset used with elements.button
//  to apply the class .button to reset default browser styles
// ==========================================================================||

@mixin button-reset() {
  // @include sf-typography('button'); // uncomment for now
  position: relative;
  z-index: 0;
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
  box-sizing: border-box !important; // qa: !important to overwite hubspot's setting
  color: $color-text-tlight;
  cursor: pointer;
  display: inline-flex;
  height: auto;
  justify-content: center;
  margin: 0;
  max-width: 100%;
  outline: none;
  padding: 0;
  text-decoration: none;
  touch-action: manipulation;
  transition: all $transition-default;
  user-select: none;
  white-space: wrap; // qa: used to be nowrap
  width: auto;

  &::-moz-focus-inner {
    border: 0;
  }

  &.is-disabled,
  &:disabled {
    cursor: not-allowed;
  }
}

// ==========================================================================||
//  mixin to calculate space of gutter/column width based on grid
//  created by Xavier Ang
// ==========================================================================||

@function column-calculation(
  $columns,
  $gutters,
  $breakpoints: 'desktop',
  $offset: '0px'
) {
  @if ($breakpoints == 'desktop') {
    @return calc(
      (
          #{$columns} * (1 / 12 *
                (
                  100vw - (2 * #{map-get($wrapper-padding, 'desktop')}) -
                    (11 * #{map-get($layout-gutter, 'desktop')})
                ))
        ) + (#{$gutters} * #{map-get($layout-gutter, 'desktop')}) + (#{$offset})
    );
  } @else if ($breakpoints == 'wide') {
    @return calc(
      (
          #{$columns} * (1 / 12 *
                (
                  #{map-get($wrapper-max-width, 'desktop')} - (
                      2 * #{map-get($wrapper-padding, 'desktop')}
                    ) - (11 * #{map-get($layout-gutter, 'desktop')})
                ))
        ) + (#{$gutters} * #{map-get($layout-gutter, 'desktop')}) + (#{$offset})
    );
  }
}
