.c-search {
  $this: &;
  color: $color-white;

  &__field {
    @include mq($from: desktop) {
      max-width: 375px;
      margin: 0 auto;
    }

    input {
      color: $color-white;
      background-color: transparent;
      border: 1px solid $color-white;
      border-radius: 50px;
      height: 40px;
      padding: 0 sp(5);
      width: 100%;

      &:focus {
        outline: none;
      }

      // IE11 clear button
      &::-ms-clear {
        display: none;
      }

      &::placeholder {
        // color: transparent; // set to transparent because label acts like our placeholder
        color: $color-white;
      }

      // to overwrite autofill styles
      // --form-bg-color is to be defined on component level
      &:-webkit-autofill {
        box-shadow: 0 0 0 20px $color-primary inset !important;
        -webkit-text-fill-color: $color-white !important;
      }
    }

    .input .input-icon {
      position: absolute;
      right: sp(4);
      top: sp(3);
    }
  }

  &__body {
    // margin-top of body | top offset of search field | height of search header | margin top of pagination | height of pagination | top offset of search field for aesthetics
    max-height: calc(100vh - 20px - 60px - 84px - 32px - 20px - 60px);
    overflow: auto;
    margin-top: sp(5);
  }

  &__common-searches {
    > ul {
      @include reset-list;
      @include make-list-inline;
      margin-top: sp(1);
      margin-bottom: -sp(3);

      > li {
        margin-right: sp(10);
        margin-bottom: sp(3);

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__results {
    > ul {
      @include reset-list;
      @include make-list-bare;

      > li {
        display: flex;
        flex-direction: column;
        margin-bottom: sp(3);

        &:last-child {
          margin-bottom: 0;
        }

        > .link {
          &:hover,
          &.is-hovered {
            color: $color-yellow;
          }
        }

        > span > em {
          font-style: normal;
          color: $color-yellow;
        }
      }
    }
  }
}
