$layout-gutter: (
  mobile: sp(6),
) !default;

// ==========================================================================||
//	Usage:
//  <div class="o-layout">
//    <div class="o-item"></div>
//  </div>
// ==========================================================================||

// validate settings

@if not variable-exists($name: layout-gutter) {
  @error "$layout-gutter was not set";
}

@if type-of($value: $layout-gutter) != 'map' {
  @error "$layout-gutter is not a map";
}

@if not map-has-values($layout-gutter) {
  @error "$layout-gutter is invalid";
}

.o-layout {
  $this: &;

  @include reset-list;
  @include make-layout;
  @include set-layout-bp-gutter($layout-gutter);

  // ==========================================================================||
  //	Modifiers
  // ==========================================================================||

  // .o-layout--flush
  // Flush all gutters

  &--flush {
    @include set-layout-gutter(0);
  }

  // .o-layout--stretch or o-layout--matchheight
  // Make all grid items have the same height

  &--matchheight,
  &--stretch {
    align-items: stretch;
  }

  // .o-layout--middle
  // Align all grid items vertically centered of each other

  &--middle {
    align-items: center;
  }

  // .o-layout--bottom
  // Align all grid items to the bottom of each other.

  &--bottom {
    align-items: flex-end;
  }

  // .o-layout--center
  // Grid system starts from the center

  &--center {
    justify-content: center;
  }

  // .o-layout--right
  // Grid system starts from the right

  &--right {
    justify-content: flex-end;
  }

  // .o-layout--left
  // Grid system starts from the left. This will likely only
  // be needed when using in conjunction with `.o-layout--reversed`.

  &--left {
    justify-content: flex-start;
  }

  // .o-layout--reversed
  // Reverse the rendered order of the grid system.

  &--reversed {
    flex-direction: row-reverse;
  }

  // .o-layout--auto
  // Let content define how large the width of each column is

  &--auto {
    > .o-item {
      flex: 0 0 auto;
      max-width: 100%;
      width: auto;
    }
  }

  // .o-layout--matchheight
  // Let content match each others in height

  &--matchheight {
    align-items: stretch;

    > .o-item {
      height: auto;
    }
  }

  // .o-layout--stackable
  // Let content have spaces in between when stacked

  &--stacked {
    @each $bp-name, $bp-padding in $layout-gutter {
      @if $bp-name == 'mobile' {
        margin-bottom: -$bp-padding;

        > .o-item {
          margin-bottom: $bp-padding;
        }
      } @else {
        @include mq($from: $bp-name) {
          margin-bottom: -$bp-padding;

          > .o-item {
            margin-bottom: $bp-padding;
          }
        }
      }
    }
  }

  // Custom layout
  // Declare it within component with -layout prefix
  // Don't put them here!
  // Use @include set-layout-gutter() and @include set-column-gutter()
}
