.c-header-menu {
  $this: &;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  opacity: 0;
  background-color: $color-primary;
  height: 100vh;
  overflow: auto;
  padding: 0;
  padding-top: $header-height--mobile;
  transition: opacity $transition-default $transition-duration,
    transform $transition-default;
  width: 100%;

  &__nav {
    padding-top: sp(4);

    ul.level-1 {
      @include reset-list;
      @include make-list-bare;
    }

    ul.level-2 {
      @include reset-list;
      @include make-list-bare;
      padding-left: sp(6);
    }

    ul > li.is-disabled > .link {
      color: rgba($color-white, 0.3);
    }
  }

  &__nav .link {
    position: relative;
    display: inline-block;
    color: $color-white;
    text-decoration: none;
    padding: sp(3) 0; // make height = 48px

    &:before {
      content: '';
      position: absolute;
      bottom: sp(3);
      left: 0;
      width: 0;
      height: 1px;
      background-color: $color-white;
      transition: width $transition-default;
    }

    &[href]:active,
    &[href].is-active {
      &:before {
        width: 100%;
      }
    }
  }

  // ==========================================================================||
  //  States
  // ==========================================================================||

  &.is-open {
    opacity: 1;
    transform: translateY(0);
    transition: transform $transition-default;
  }
}
