// ==========================================================================||
//  $colors map is defined in settings.colors
// ==========================================================================||

$background-colors: () !default;

@each $color-namespace, $color in $background-colors {
  .u-bg-color-#{$color-namespace} {
    background-color: $color !important;
  }
}

// ==========================================================================||
//  Custom
// ==========================================================================||

.u-bg-color-primary-gradient {
  background: linear-gradient(76.32deg, #005da8 -0.45%, #004175 99.5%);
}
