$wrapper-max-width: (
  desktop: 1200px,
) !default;
$wrapper-padding: (
  mobile: sp(6),
) !default;

// ==========================================================================||
//	Usage:
//  <div class="o-wrapper"></div>
//  <div class="o-wrapper--fluid"></div>
//  <div class="o-wrapper--flush"></div>
//  <div class="o-wrapper--flush@mobile-only"></div>
//  <div class="o-wrapper--flush@mobile-and-tablet"></div>
// ==========================================================================||

// validate settings

@if not variable-exists($name: wrapper-max-width) {
  @error "$wrapper-max-width was not set";
}

@if type-of($value: $wrapper-max-width) != 'map' {
  @error "$wrapper-max-width is not a map";
}

@if not map-has-values($wrapper-max-width) {
  @error "$wrapper-max-width is invalid";
}

@if not variable-exists($name: wrapper-padding) {
  @error "$wrapper-padding was not set";
}

@if type-of($value: $wrapper-padding) != 'map' {
  @error "$wrapper-padding is not a map";
}

@if not map-has-values($wrapper-padding) {
  @error "$wrapper-padding is invalid";
}

.o-wrapper {
  $this: &;

  @include make-wrapper;
  @include set-wrapper-bp-max-width($wrapper-max-width);
  @include set-wrapper-bp-padding($wrapper-padding);

  // ==========================================================================||
  //	Outer wrapper
  // ==========================================================================||

  &--outer {
    @include set-wrapper-max-width($wrapper-outer-max-width);
    padding-left: 0;
    padding-right: 0;
  }

  // ==========================================================================||
  //  Fullbleed wrapper
  // ==========================================================================||

  &--fullbleed {
    @include set-wrapper-max-width($wrapper-fullbleed-max-width);
    padding-left: 0;
    padding-right: 0;
  }

  // ==========================================================================||
  //	Flushed o-wrapper only on mobile
  // ==========================================================================||

  &--flush\@mobile-only {
    @include mq($until: tablet) {
      @include set-wrapper-padding(0);
    }
  }

  // ==========================================================================||
  //	Flushed o-wrapper on mobile + tablet
  // ==========================================================================||

  &--flush\@mobile-and-tablet {
    @include mq($until: desktop) {
      @include set-wrapper-padding(0);
    }
  }

  // ==========================================================================||
  //	Flushed o-wrapper on all breakpoint
  // ==========================================================================||

  &--flush {
    @include set-wrapper-padding(0);
    @include set-wrapper-max-width(none);
  }

  // ==========================================================================||
  //	Remove max-width rule on o-wrapper
  // ==========================================================================||

  &--fluid {
    @include set-wrapper-max-width(none);
  }

  // ==========================================================================||
  //  Custom
  // ==========================================================================||

  // 10/12 grid
  &--inset {
    @include set-wrapper-max-width(1080px);
  }
}
