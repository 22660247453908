.c-card-result {
  $this: &;

  border-color: $color-primary;
  background-color: $color-white;
  color: $color-primary;
  text-align: center;
  padding: sp(5);

  &__content {
    h3 {
      margin-top: sp(3);
    }
  }

  &:hover,
  &.is-hovered,
  &:active,
  &.is-active {
    &,
    &:focus,
    &.is-focused {
      background-color: $color-primary;
      color: $color-white;
    }
  }

  &:focus,
  &.is-focused {
    background-color: $color-bgblue;
  }

  &:active,
  &.is-active {
  }

  &:disabled,
  &.is-disabled {
    border-color: $color-disabled;
    background-color: $color-white;
    color: $color-disabled;
  }
}
