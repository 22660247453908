.c-card-link {
  $this: &;

  padding: sp(5) sp(6);
  border-color: $color-primary;
  color: $color-black;

  &__content {
    h3 {
      color: $color-primary;
      height: 2 * 28px;
      overflow: hidden;

      @include mq($from: desktop) {
        height: 2 * 32px;
      }
    }

    p {
      margin-top: sp(4);
      height: 2 * 24px;
      overflow: hidden;
    }
  }

  &__buttons {
    margin-top: sp(5);
  }

  // ==========================================================================||
  //  States
  // ==========================================================================||

  &:hover,
  &.is-hovered {
    &,
    &:focus,
    &.is-focused {
      border-color: $color-black;
    }
  }

  &:focus,
  &.is-focused {
    border-color: $color-bgblue;
  }

  &:disabled,
  &.is-disabled {
    color: $color-disabled;

    #{$this}__content {
      h3 {
        color: $color-disabled;
      }
    }

    #{$this}__buttons {
      .button {
        @extend .is-disabled;
      }
    }
  }
}

.c-card-link-layout {
  @include set-layout-gutter(sp(9));
  margin-bottom: -sp(9);

  > .o-item {
    @include set-column-gutter(sp(9));
    margin-bottom: sp(9);
  }
}
