// ==========================================================================||
//  Card (Primary)
//  - Consists of a 6:5 image and a call to action
//  - Centered align
// ==========================================================================||

.c-card-primary {
  $this: &;

  text-align: center;
  padding: sp(4);
  border-color: $color-primary;
  color: $color-black;
  display: flex;
  flex-direction: column;

  &__image {
    border-radius: $border-radius-default;
    overflow: hidden;
    margin-bottom: sp(3);
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }

  &__content {
    h3 {
      max-height: 2 * 32px;
      overflow: hidden;
    }

    p {
      margin-top: sp(1);
      max-height: 2 * 24px;
      overflow: hidden;
    }
  }

  &__buttons {
    margin-top: sp(3);
  }

  // ==========================================================================||
  //  States
  // ==========================================================================||

  &:hover,
  &.is-hovered {
    &,
    &:focus,
    &.is-focused {
      border-color: $color-black;
    }
  }

  &:focus,
  &.is-focused {
    border-color: $color-bgblue;
  }

  &:disabled,
  &.is-disabled {
    #{$this}__image {
      filter: grayscale(1);
    }

    #{$this}__buttons {
      .button {
        @extend .is-disabled;
      }
    }
  }
}
