@mixin hf-show-hide($breakpoint: null) {
  // .u-show-block#{$breakpoint} {
  //   display: block !important;
  // }

  // .u-show-inline-block#{$breakpoint} {
  //   display: inline-block !important;
  // }

  // .u-show-flex#{$breakpoint} {
  //   display: flex !important;
  // }

  // .u-show-inline-flex#{$breakpoint} {
  //   display: inline-flex !important;
  // }

  .u-hide#{$breakpoint} {
    display: none !important;
  }
}

@include hf-show-hide();

@each $bp-name, $bp-value in $mq-breakpoints {
  @include mq($from: $bp-name) {
    @include hf-show-hide(#{$global-separator}#{$bp-name});
  }
}

// Special extras
// hide on mobile/tablet

.u-hide#{$global-separator}mobile-and-tablet {
  @include mq($until: desktop) {
    display: none !important;
  }
}

.u-hide#{$global-separator}mobile-and-tablet1024 {
  @include mq($until: desktop1024) {
    display: none !important;
  }
}

// hide on mobile only

.u-hide#{$global-separator}mobile-only {
  @include mq($until: tablet) {
    display: none !important;
  }
}

// hide on tablet only

.u-hide#{$global-separator}tablet-only {
  @include mq($from: tablet, $until: desktop) {
    display: none !important;
  }
}

// hide until wide
.u-hide#{$global-separator}until-wide {
  @include mq($until: wide) {
    display: none !important;
  }
}
