.c-footer {
  $this: &;

  background-color: $color-black;
  color: $color-white;

  &__main {
    position: relative;
    padding: sp(8) 0 sp(4);

    @include mq($from: desktop) {
      height: 190px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $color-white;
      padding: 0;
    }
  }

  &__wrapper {
    @include mq($from: desktop) {
      display: flex;
      align-items: center;
    }
  }

  &__logo {
    padding: sp(8) 0 0;

    & img {
      margin: 0 auto;
      height: 70px;
    }
  }

  &__brand {
    display: block;

    @include mq($until: desktop) {
      margin-bottom: sp(10);
    }

    svg {
      display: block;
      width: 160px;
      height: 160px;
      margin: 0 auto;
      // transition: all $transition-default;

      @include mq($from: desktop) {
        width: 116px;
        height: 116px;
      }
    }
  }

  &__nav {
    margin-left: auto;
    margin-right: auto;

    @include mq($until: desktop) {
      text-align: center;
    }

    ul.level-1 {
      @include reset-list;

      @include mq($until: desktop) {
        @include make-list-bare;
      }

      @include mq($from: desktop) {
        @include make-list-inline;

        > li {
          margin-right: sp(8);

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  &__nav .link {
    position: relative;
    color: $color-white;
    text-decoration: none;

    &:before {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 0;
      height: 1px;
      background-color: $color-white;
      transition: width $transition-default;
    }

    @include mq($until: desktop) {
      display: inline-block;
      padding: 12px 0;
    }

    &[href]:hover,
    &[href].is-hovered,
    &[href]:focus,
    &[href].is-focused,
    &[href]:active,
    &[href].is-active {
      &:before {
        width: 100%;
      }
    }
  }

  &__nav ul.level-1 > li.has-children:hover > .link,
  &__nav ul.level-1 > li.has-children:focus-within > .link {
    &:before {
      width: 100%;
    }
  }

  ul > li.is-disabled > .link {
    color: rgba($color-white, 0.3);
  }

  &__bottom {
    padding: sp(8) 0;

    @include mq($from: desktop) {
      padding: sp(8) 0 sp(12);
    }
  }
}
