// ==========================================================================||
//  Used with .o-ratio.o-ratio--1:1
// ==========================================================================||

.c-card-flow {
  $this: &;

  position: relative;
  border-color: $color-primary;
  background-color: $color-primary;
  color: $color-white;

  &__content {
    display: flex;
    align-items: flex-end;
    padding: sp(5) sp(6);

    > h3 {
      overflow: hidden;
      height: 3 * 32px;

      @include mq($from: desktop) {
        height: 3 * 34px;
      }
    }
  }

  &__step {
    position: absolute;
    top: sp(4);
    left: sp(6);
  }

  // ==========================================================================||
  //  States
  // ==========================================================================||

  &:hover,
  &.is-hovered,
  &:active,
  &.is-active {
    &,
    &:focus,
    &.is-focused {
      background-color: $color-white;
      color: $color-primary;
    }
  }

  &:focus,
  &.is-focused {
    background-color: $color-bgblue;
    color: $color-white;
  }

  &:disabled,
  &.is-disabled {
    border-color: $color-disabled;
    background-color: $color-white;
    color: $color-disabled;
  }
}
