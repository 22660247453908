// ==========================================================================||
//  c-card-profile is not tied to card element
// ==========================================================================||

.c-card-profile {
  $this: &;

  &__image {
    width: 165px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto sp(3);
  }

  // &__body {}
  // &__name {}
  // &__designation {}
  // &__desc {}
}

.c-card-profile-layout {
  @include set-layout-gutter(sp(12));
  margin-bottom: -sp(12);

  @include mq($from: desktop) {
    @include set-layout-gutter(sp(20));
    margin-bottom: -sp(20);
  }

  > .o-item {
    @include set-column-gutter(sp(12));
    margin-bottom: sp(12);

    @include mq($from: desktop) {
      @include set-column-gutter(sp(20));
      margin-bottom: sp(20);
    }
  }
}
