// ==========================================================================||
//  Hamburgers
//  elements.hamburgers
// ==========================================================================||

$hamburger-padding-x: 7px;
$hamburger-padding-y: 9px;
$hamburger-layer-width: 18px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 4px;
$hamburger-layer-color: $color-white;
$hamburger-layer-border-radius: 0;

$hamburger-hover-opacity: 1;
$hamburger-hover-transition-duration: $transition-duration;
$hamburger-hover-transition-timing-function: $transition-easing;
$hamburger-hover-use-filter: false;
$hamburger-hover-filter: opacity(100%);

$hamburger-active-layer-color: $color-white;
$hamburger-active-hover-opacity: 1;
$hamburger-active-hover-filter: opacity(100%);

$hamburger-types: (elastic);
