@mixin c-button-primary() {
  border-radius: $border-radius-default;
  background-color: $color-primary;
  color: $color-white;
  padding: 12px 24px;
  min-height: 44px;

  .sprite svg {
    fill: $color-white;
  }

  &:hover,
  &.is-hovered {
    &,
    &:focus,
    &.is-focused {
      background-color: $color-black;
    }
  }

  &:focus,
  &.is-focused {
    background-color: $color-bgblue;
  }

  &:active,
  &.is-active {
    background-color: $color-black;
  }

  &:disabled,
  &.is-disabled {
    background-color: $color-disabled;
  }
}

.c-button-primary {
  @include c-button-primary;
}
